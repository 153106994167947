<template>
  <div>
    <FrontendMainNav
      :show-links="false"
      :logo="{ logoUrl: iframeConfig?.logo || '', referralDomain: iframeConfig?.referralDomain || '' }"
      :button-color="iframeConfig?.highLightSecondaryColor || ''"
    />
    <!-- Page content -->
    <slot />
  </div>
</template>
<script setup lang="ts">
const { iframeConfig } = storeToRefs(useIframeConfigStore())
</script>
